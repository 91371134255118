<template>
  <div class="hero--small">
    <div class="container--basic">
      <i :class="icon" />
      <h1>{{ text }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroSmall',
  props: {
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
