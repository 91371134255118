<template>
  <a
    :href="linkHref"
    class="card--theme__anchor-wrapper"
  >
    <div class="card--theme">
      <div class="card--theme__top">
        <div class="icon--theme">
          <img
            :src="card.icon"
            :alt="`${card.title} Icon`"
          >
        </div>

        <small
          v-text="card.title"
        />
      </div>

      <div class="card--theme__bottom">
        <p class="card--theme__description">{{ card.description }}</p>

        <a
          class="card--theme__view-link"
          :href="linkHref"
          @mouseenter="hover = true"
          @mouseleave="hover = false"
        >
          <small>EXPLORE RESOURCES</small>

          <div
            class="icon--right-arrow"
            :class="{ active: hover }"
          >
            <icon-right-arrow />
          </div>
        </a>
      </div>
    </div>
  </a>
</template>

<script>
import IconRightArrow from '../icons/IconRightArrow'

export default {
  name: 'CardTheme',

  components: {
    IconRightArrow
  },

  props: {
    card: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      hover: false
    }
  },

  computed: {
    linkHref () {
      return 'search?theme=' + this.card.slug
    }
  }
}
</script>
