<template>
  <div class="licence-data">
    <h4
      v-if="computedContent.subtitle_one"
      class="licence-data__heading"
      v-text="computedContent.subtitle_one"
    />
    <p
      v-if="computedContent.text_one"
      class="licence-data__text"
      v-text="computedContent.text_one"
    />

    <h4
      v-if="computedContent.subtitle_two"
      class="licence-data__heading"
      v-text="computedContent.subtitle_two"
    />
    <p
      v-if="computedContent.text_two"
      class="licence-data__text"
      v-text="computedContent.text_two"
    />
  </div>
</template>

<script>
export default {
  name: 'LicenceData',

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    computedContent () {
      return typeof this.content === 'object' && this.content !== null ? this.content : {}
    }
  }
}
</script>
