<template>
  <div>
    <modal
      v-model="isActive"
      modal-window-class="menu"
    >
      <template #button>
        <button
          class="navbar__link icon--burger"
          slot:button
          @click="onOpenModalButtonClick"
        >
          <div class="burger-bar" />
          <div class="burger-bar" />
          <div class="burger-bar" />
        </button>
      </template>

      <template #content>
        <ul class="menu__list">
          <div class="menu__list__section">
            <li class="menu__list__item">
              <h6 class="menu__list__title">
                IMPACT AREAS
              </h6>
            </li>
            <li
              v-for="link in impactAreas"
              :key="link.title"
              class="menu__list__item"
            >
              <a
                :href="constructLink(link.slug)"
                class="navbar__link-text"
                v-text="link.title"
              />
            </li>
          </div>

          <div class="menu__list__section">
            <li class="menu__list__item">
              <h6 class="menu__list__title">
                INNOVATION AREAS
              </h6>
            </li>
            <li
              v-for="link in innovationAreas"
              :key="link.title"
              class="menu__list__item"
            >
              <a
                :href="constructLink(link.slug)"
                class="navbar__link-text"
                v-text="link.title"
              />
            </li>
          </div>
        </ul>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './ComponentModal.vue'
import { constructLink } from '../../helpers/link-helpers'

export default {
  name: 'ModalMenu',

  components: {
    Modal
  },

  props: {
    links: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    impactAreas () {
      return this.links.slice(0, 4)
    },

    innovationAreas () {
      return this.links.slice(4)
    }
  },

  methods: {
    onOpenModalButtonClick () {
      this.setIsActive(true)
    },

    setIsActive (isActive) {
      this.isActive = isActive === true
    },

    constructLink
  }
}
</script>
