<template>
  <div class="checkbox-with-label">
    <button
      :class="[`checkbox-with-label__checkbox--${checkboxColour}`, {active: checked }]"
      @click="toggleChecked"
    />
    <small
      class="checkbox-with-label__text"
      v-text="text"
    />
  </div>
</template>

<script>
export default {
  name: 'CheckboxWithLabel',
  props: {
    text: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    checkboxColour: {
      type: String,
      default: 'white'
    }
  },
  data () {
    return {
      checked: false
    }
  },
  methods: {
    toggleChecked () {
      this.checked = !this.checked
      this.$emit(`checked:${this.id}`, this.checked)
    }
  }
}
</script>
