<template>
  <div class="card--carousel">
    <div 
      class="card--carousel__image-wrap"
      :class="isSvg ? 'svg' : 'img'"
    />

    <!-- Preloaded image inserted here -->
    <div class="card--carousel__text">
      <div class="card--carousel__header">
        <small 
          class="card--carousel__subtitle"
          v-text="type"
        />

        <small
          v-if="displayProteusBadge"
          class="card--carousel__proteus-badge"
          v-text="'Proteus'"
        />
      </div>

      <h4 
        class="card--carousel__title"
        v-text="card.title"
      />

      <p 
        class="card--carousel__description"
        v-text="card.description"
      />
    </div>

    <a
      :href="`/products/${card.id}`"
      class="card--carousel__link"
    />
  </div>
</template>

<script>
export default {
  name: "CardCarousel",

  props: {
    card: {
      type: Object,
      required: true,
    },
      
    displayProteusBadge: {
      type: Boolean,
      default: false,
    },

    pictureUrl: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "PRODUCT TYPE",
    },
  },

  computed: {
    isSvg () {
      return /\.svg$/.test(this.card.image.src)
    },
  },

  mounted () {
    this.$el
      .querySelector(".card--carousel__image-wrap")
      .appendChild(this.card.image)
  },
};
</script>
