/**
 * objectToQuerystring
 *
 * @param {Object} paramsObject e.g. { foo: "hi there", bar: "100%" }
 * @returns String e.g. foo=hi+there&bar=100%25
 */
export default function (paramsObject) {
  const searchParams = new URLSearchParams()

  Object.keys(paramsObject).forEach(key => searchParams.append(key, paramsObject[key]))

  return searchParams.toString()
}
