<template>
  <div class="filters--search">
    <div
      v-if="!dataLoaded"
      class="filters--search__overlay"
    />
    <template v-if="dataLoaded">
      <ul class="filters--search__filters">
        <filter-search
          v-for="(filter, index) in filters"
          :key="index"
          :filter="filter"
          @filters:changed="getFilteredResults"
        />
      </ul>
    </template>
  </div>
</template>

<script>
import FilterSearch from "./FilterSearch";

export default {
  name: "FiltersSearch",

  components: {
    FilterSearch,
  },

  // This is just temporary for testing purposes, the actual component won't need props
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      dataLoaded: true,
    };
  },

  mounted() {
    this.$root.$on("data:loaded", this.populateFilters);
  },

  methods: {
    populateFilters() {
      this.dataLoaded = true;
    },
  },
};
</script>
