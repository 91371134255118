<template>
  <div class="avatar">
    <a href="/account/dashboard">
      <span
        class="avatar__button"
        :class="{ 'active': isDashboardPage }"
      >
        <p 
          class="avatar__button-text"
          v-text="userInitials"
        />
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ComponentAvatar',

  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },


  computed: {
    userInitials () {
      const initials = this.user.given_name.slice(0, 1) + this.user.family_name.slice(0, 1)
      if (initials.length > 0) {
        return initials
      } else {
        return '  '
      }
    },

    isDashboardPage () {
      return window.location.pathname == '/account/dashboard'
    }
  }
}
</script>
