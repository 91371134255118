<template>
  <button
    class="button"
    @click="onButtonClick"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  name: 'CopyTrigger',

  props: {
    idToCopy: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      hasButtonBeenClicked: false
    }
  },

  computed: {
    textToCopy () {
      return document.getElementById(this.idToCopy).innerText
    },

    buttonText () {
      return this.hasButtonBeenClicked ? 'Copied' : 'Copy'
    }
  },

  methods: {
    onButtonClick () {
      this.copyTextToClipboard(this.textToCopy)

      if (this.hasButtonBeenClicked === false) { this.setButtonClicked(true) }
    },

    copyTextToClipboard (text) {
      const tempInput = document.createElement('input')

      tempInput.setAttribute('type', 'text')
      tempInput.setAttribute('value', text)
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
    },

    setButtonClicked (isButtonClicked) {
      this.hasButtonBeenClicked = isButtonClicked === true
    }

  }
}
</script>
