<template>
  <div class="product-grid__card-text">
    <h2 
      class="product-grid__card-title"
      v-text="title"
    />

    <div
      class="product-grid__card-description"
      v-text="description"
    />

    <p class="product-grid__card-date">
      <span class="product-grid__card-date-title">{{ dateTitle }}:</span>
      
      {{ date }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',

  props: {

    title: {
      type: String,
      required: true
    },

    date: {
      type: String,
      default: 'No date found'
    },

    dateTitle: {
      required: true,
      type: String
    },

    description: {
      type: String,
      default: 'No description found'
    }

  }
}
</script>
