<template>
  <div>
    <modal
      v-model="isActive"
      class="modal--metadata"
    >
      <template #button>
        <button
          class="button--metadata"
          @click="onOpenButtonClick"
        >
          Metadata
        </button>
      </template>
      <template #content>
        <h3 
          class="modal__title"
          v-text="title"
        />

        <ul class="modal__ul">
          <li
            v-for="({ label, content }, index) in filteredMetadataList"
            :key="index"
            class="modal__li"
          >
            <span 
              class="modal__li__label"
              v-text="label"
            />

            <span
              class="modal__li__content"
              v-text="content"
            />
          </li>
        </ul>

        <button
          class="button--blue"
          @click="onCloseButtonClick"
        >
          Close
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './ComponentModal'

export default {
  name: 'ModalMetadata',

  components: {
    Modal
  },

  props: {
    title: {
      type: String,
      required: true
    },

    metadataList: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    filteredMetadataList () {
      // A safety net. Rather than use metadata prop directly, use this filtered list
      // to ensure all metadata rendered is sanitised.
      return this.metadataList.filter(this.metadataFilterCallback)
    }
  },

  methods: {
    onCloseButtonClick () {
      this.setIsActive(false)
    },

    onOpenButtonClick () {
      this.setIsActive(true)
    },

    metadataFilterCallback: ({ content }) => {
      // A callback method that all metadata must 'pass' in filteredMetadataList()
      // Currently just filters out empty strings and null values.
      return content
    },

    setIsActive (isActive) {
      this.isActive = isActive === true
    }
  }
}
</script>
