<template>
  <div class="pagination">
    <div class="pagination__inner">
      <!-- Text showing current location in available pages -->
      <span class="pagination__info">
        (Currently viewing page {{ currentPage }} of {{ pages }} )
      </span>

      <ul class="pagination__buttons">
        <!-- 'First page' button -->
        <li>
          <button
            class="button--pagination"
            :class="{ disabled: onFirstPage }"
            :disabled="onFirstPage"
            @click="showPage(0)"
          >
            <div class="icon--double-chevrons--reversed">
              <icon-double-chevrons />
            </div>
            <span class="visually-hidden">Go to the first page</span>
          </button>
        </li>

        <!-- 'Previous page' button -->
        <li>
          <button
            class="button--pagination"
            :class="{ disabled: onFirstPage }"
            :disabled="onFirstPage"
            @click="showPage(currentPage - 2)"
          >
            <div class="icon--right-chevron--reversed">
              <icon-right-chevron />
            </div>
            <span class="visually-hidden">Go to the previous page</span>
          </button>
        </li>

        <!-- Numbered Pages -->
        <li
          v-for="page in pagesToDisplay"
          :key="page"
        >
          <button
            class="button--pagination"
            :class="{ selected: onCurrentPage(page) }"
            :disabled="onCurrentPage(page)"
            @click="showPage(page)"
          >
            {{ page + 1 }}
          </button>
        </li>

        <!-- 'Next page' button -->
        <li>
          <button
            class="button--pagination"
            :class="{ disabled: onFinalPage }"
            :disabled="onFinalPage"
            @click="showPage(currentPage)"
          >
            <div class="icon--right-chevron">
              <icon-right-chevron />
            </div>

            <span class="visually-hidden">Go to the next page</span>
          </button>
        </li>

        <!-- 'Last page' button -->
        <li>
          <button
            class="button--pagination"
            :class="{ disabled: onFinalPage }"
            :disabled="onFinalPage"
            @click="showPage(pages - 1)"
          >
            <div class="icon--double-chevrons">
              <icon-double-chevrons />
            </div>

            <span class="visually-hidden">Go to the final page</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconRightChevron from '../icons/IconRightChevron'
import IconDoubleChevrons from '../icons/IconDoubleChevrons'

export default {
  name: 'ComponentPagination',

  components: {
    IconRightChevron,
    IconDoubleChevrons
  },

  props: {
    itemsNumber: {
      type: Number,
      required: true
    },

    totalItems: {
      type: Number,
      required: true
    },

    pages: {
      type: Number,
      required: true
    }
  },

  emits: [
    'update:page'
  ],

  data () {
    return {
      currentPage: 1
    }
  },

  computed: {
    onFirstPage () {
      return this.currentPage === 1
    },

    onFinalPage () {
      return this.currentPage === this.pages
    },

    pagesToDisplay () {
      const currentPageIndex = this.currentPage

      const pagesIndexArray = [...Array(this.pages).keys()] // Generate an array using the Number this.pages prop.
      const precedingPages = pagesIndexArray.slice(0, currentPageIndex)
      const subsequentPages = pagesIndexArray.slice(currentPageIndex)

      // Keep populating pagesToDisplay until it either has 10 elements in it or it's as long as all the pages available
      const pagesToDisplay = []
      while (pagesToDisplay.length < 10 && pagesToDisplay.length < this.pages) {
        if (precedingPages.length > 0) { pagesToDisplay.unshift(precedingPages.pop()) }
        if (pagesToDisplay.length >= 10 || pagesToDisplay.length >= this.pages) { break }
        if (subsequentPages.length > 0) { pagesToDisplay.push(subsequentPages.shift()) }
      }

      return pagesToDisplay
    }
  },

  mounted () {
    this.$root.$on('reset:page', this.resetPage)
  },

  methods: {
    resetPage () {
      this.currentPage = 1
    },

    showPage (index) {
      this.currentPage = index + 1
      this.$store.dispatch('search/changePage', this.currentPage)
      this.$emit('update:page')
    },

    onCurrentPage (index) {
      return this.currentPage === index + 1
    }
  }
}
</script>
