<template>
  <div class="table--filterable__banner">
    <div class="table--filterable__banner-text">
      <div class="table--filterable__banner-icon">
        <component :is="currentIcon" />
      </div>
      <h3>{{ text.main_text }}</h3>
      <p class="table--filterable__banner-supplement">
        {{ text.supplementary_text }}
      </p>

      <template v-if="text.url">
        <a
          :href="text.url"
          class="table--filterable__banner-link"
        ><p>{{ text.link_text }}</p>
          <i class="icon--right-chevron-black" /></a>
      </template>
    </div>
  </div>
</template>

<script>
import IconEmptyTray from '../icons/IconEmptyTray'

export default {
  name: 'TableFilterableBanner',
  components: {
    IconEmptyTray
  },
  props: {
    text: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      components: ['IconEmptyTray']
    }
  },
  computed: {
    currentIcon () {
      return this.text.icon
    }
  }
}
</script>
