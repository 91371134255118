<template>
  <div :class="getCssClass">
    <img :src="src">
  </div>
</template>

<script>
export default {
  name: 'ProductImage',

  props: {
    cssClass: {
      type: String,
      default: ''
    },
    isPlaceholder: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: ''
    }
  },

  computed: {
    getCssClass () {
      return this.isPlaceholder ? `${this.cssClass}--placeholder` : this.cssClass
    }
  }
}
</script>
