<template>
  <div>
    <slot name="button" />
    <div
      class="modal__background"
      :class="{ active: isActive }"
    >
      <div
        class="modal__window"
        :class="[ modalWindowClass, { active: isActive } ]"
      >
        <div
          class="icon--cross"
          @click="onCloseButtonClick"
        >
          <icon-cross />
        </div>
        <div
          class="modal__inner-window"
          :class="modalWindowClass"
        >
          <div class="modal__content">
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCross from '../icons/IconCross'

export default {
  name: 'ComponentModal',

  components: {
    IconCross
  },

  model: {
    event: 'toggle',
    prop: 'isActive'
  },

  props: {
    isActive: {
      type: Boolean,
      default: false
    },

    modalWindowClass: {
      type: String,
      default: undefined
    }
  },

  emits: [
    'toggle'
  ],

  methods: {
    onCloseButtonClick () {
      this.setIsActive(false)
    },

    setIsActive (isActive) {
      this.$emit('toggle', isActive === true)
    }
  }
}
</script>
