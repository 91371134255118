<template>
  <div class="filters--sort">
    <Survey :survey="survey" />
  </div>
</template>

<script>
import * as SurveyVue from 'survey-vue'
import 'survey-vue/survey.min.css'

const Survey = SurveyVue.Survey

export default {
  name: 'FiltersSort',

  components: {
    Survey
  },

  emits: [
    'update:sort'
  ],

  data () {
    return {
      dataLoaded: true,
      survey: null,
      surveyJson: {
        elements: [
          {
            allowClear: false,
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
            titleLocation: 'hidden',
            hideNumber: true,
            optionsCaption: 'Sort by',
            name: 'sort',
            type: 'dropdown',
            choices: [
              { value: 0, text: 'Most popular' },
              { value: 1, text: 'Recently published' },
              { value: 2, text: 'Alphabetical (A-Z)' }
            ]
          }
        ]
      }
    }
  },

  created () {
    this.survey = new SurveyVue.Model(this.surveyJson)
    this.survey.onValueChanged.add(this.updateSelectedSort)
    this.survey.showNavigationButtons = false

    this.$root.$on('reset:sort', this.reset)
  },

  mounted () {
    this.$root.$on('data:loaded', this.populateFilters)
  },

  methods: {
    populateFilters () {
      this.dataLoaded = true
    },

    reset () {
      this.survey.clear()
    },

    updateSelectedSort (sender) {
      this.$store.dispatch('search/updateSort', sender.data)
      this.$emit('update:sort')
    }
  }
}
</script>
