export const storeSearch = {
  namespaced: true,

  state: {
    appliedFilters: [],
    requestedPage: 1,
    searchTerm: '',
    selectedSort: ''
  },

  actions: {
    changePage ({ commit }, payload) {
      commit('updateRequestedPage', payload)
    },

    resetFilters ({ commit }) {
      commit('resetAppliedFilters')
    },

    resetSort ({ commit }) {
      commit('updateSelectedSort', '')
    },

    search ({ commit }, payload) {
      commit('updateSearchTerm', payload)
    },

    updateFilters ({ commit, state }, payload) {
      const filterIndex = state.appliedFilters.findIndex(filter => {
        return filter.filterType == payload.filterType
      })

      if (filterIndex > -1) {
        commit('editAppliedFilters', { index: filterIndex, filters: payload })
      } else {
        commit('updateAppliedFilters', payload)
      }
    },

    updateSort ({ commit }, payload) {
      commit('updateSelectedSort', payload.sort)
    }
  },

  mutations: {
    editAppliedFilters (state, payload) {
      state.appliedFilters[payload.index] = payload.filters
    },

    resetAppliedFilters (state) {
      state.appliedFilters = []
    },

    updateAppliedFilters (state, payload) {
      state.appliedFilters.push(payload)
    },

    updateRequestedPage (state, requestedPage) {
      state.requestedPage = requestedPage
    },

    updateSelectedSort (state, selectedSort) {
      state.selectedSort = selectedSort
    },

    updateSearchTerm (state, searchTerm) {
      state.searchTerm = searchTerm
    }
  }
}
