<template>
  <li class="filter--search__value">
    <div class="checkbox-with-label">
      <span
        class="checkbox-with-label__checkbox--black"
        :class="{ active: isChecked }"
        @click="applyFilter"
      />

      <small 
        class="checkbox-with-label__text"
        v-text="filterOption"
      />
    </div>
  </li>
</template>

<script>
export default {
  name: 'FilterOption',

  props: {
    filterOption: {
      type: String,
      required: true
    },

    isPreSelected: {
      default: false,
      type: Boolean
    }
  },

  emits: [
    'filter:select',
    'filter:deselect'
  ],

  data () {
    return {
      isChecked: false
    }
  },

  created () {
    if (this.isPreSelected === true) {
      this.applyFilter()
    }

    this.$root.$on('reset:filter-options', this.reset)
  },

  methods: {
    applyFilter () {
      this.isChecked = !this.isChecked

      if (this.isChecked) {
        this.$emit('filter:select', this.filterOption)
      } else {
        this.$emit('filter:deselect', this.filterOption)
      }
    },

    reset () {
      this.isChecked = false
    }
  }
}
</script>
