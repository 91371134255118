<template>
  <li class="filter--data__option">
    <!-- TODO - replace with large checkbox with animation -->
    <input
      :id="optionId"
      v-model="isSelected"
      type="checkbox"
      class="filter__checkbox"
      :class="{ 'filter__checkbox--active' : isSelected }"
    >
    <label
      :for="optionId"
      class="filter--data__checkbox-label"
    >{{ option }}</label>
  </li>
</template>

<script>
export default {
  name: 'DataFilterOption',

  props: {
    option: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      isSelected: false
    }
  },

  computed: {
    optionId () {
      return this.option.toString().replace(' |(|)|_', '-').toLowerCase()
    }
  }
}
</script>
