<template>
  <div>
    <modal
      v-model="isActive"
      modal-window-class="errors"
    >
      <template #content>
        <h2
          v-text="errorCountMessage"
        />

        <ul class="modal__error-list">
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            <p v-text="error" />
          </li>
        </ul>

        <h3 v-text="fixMessage" />
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './ComponentModal'

export default {
  name: 'ModalErrors',

  components: {
    Modal
  },

  props: {
    errors: {
      type: Array,
      default: () => ([])
    },

    errorCountMessage: {
      type: String,
      default: ''
    },

    fixMessage: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      isActive: true
    }
  }
}
</script>
