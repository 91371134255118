<template>
  <div class="filter--data__wrapper">
    <button
      v-if="hasOptions"
      class="button--filter"
      :class="{ 'has-selected': hasSelected }"
      @click="toggleSelect"
    >
      <small class="filter--data__label">
        {{ title }}
        <div
          class="icon--drop-arrow"
          :class="{ active: isActive }"
        >
          <icon-drop-arrow />
        </div>
      </small>
    </button>

    <div
      v-show="isActive"
      class="filter--data__options"
    >
      <ul class="filter--data__options-list">
        <template v-for="(option, index) in options">
          <data-filter-option
            v-if="option != null"
            :key="`${title}-${index}`"
            :option="option"
            :selected="false"
          />
        </template>
      </ul>

      <div class="filter--data__buttons">
        <button
          type="button"
          class="button--link"
          @click="clear"
        >
          Clear
        </button>
        <button
          type="button"
          class="button--transparent"
          @click="apply"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import mixinPopupCloseListeners from '../../mixins/mixin-popup-close-listeners'

import DataFilterOption from './DataFilterOption'
import IconDropArrow from '../icons/IconDropArrow'

export default {
  name: 'FilterData',

  components: {
    DataFilterOption,
    IconDropArrow
  },

  mixins: [mixinPopupCloseListeners({ closeCallback: 'toggleSelect', toggleVariable: 'isActive' })],

  props: {
    title: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      children: this.$children,
      isActive: false,
      options: undefined,
      activeOptions: []
    }
  },

  computed: {
    hasOptions () {
      return this.options != undefined
    },

    selectedOptions () {
      return this.children
        .filter(child => child.isSelected)
        .map(child => child.option)
    },

    hasSelected () {
      return this.$store.state.table.selectedFilterOptions.find((filter) => {
        return filter.title == this.title
      }).options.length > 0
    }
  },

  mounted () {
    this.options = this.$store.getters['table/fetchFilters'][this.title]
  },

  methods: {
    toggleSelect () {
      // if (event.target !== event.currentTarget) {  return }

      this.isActive = !this.isActive
    },

    clear () {
      // set the isSelected property on all options to false
      this.children.forEach(child => {
        child.isSelected = false
      })
    },

    apply () {
      this.toggleSelect()

      // update the active filters array
      this.activeOptions = this.selectedOptions

      const newFilterOptions = {
        filter: this.title,
        options: this.activeOptions
      }

      this.$store.dispatch('table/updateFilterOptions', newFilterOptions)
      this.$store.dispatch('table/updateRequestedPage', 1)

      this.$eventHub.$emit('getNewItems')
      this.$eventHub.$emit('filtersApplied')
    }
  }
}
</script>
