<template>
  <div>
    <DropdownLicense
      :options="optionsVersions"
      @version:updated="updateOptions"
    />
    <Modal
      v-model="isActive"
      :modal-window-class="'license'"
    >
      <template #button>
        <a
          v-if="isPlatform || isGeoSpatial"
          class="button--purchase"
          :href="endpoint"
          target="_blank"
          @click="sendReportOfUserAccess(); onAccessResourceClick()"
        >
          <small>ACCESS RESOURCE</small>
          <span class="button--purchase-icon">
            <icon-external-link />
          </span>
        </a>
        <!-- TO BE USED WHEN DONATION BACK IN -->
        <!-- <button
          v-else
          class="button--purchase"
          @click="onOpenModalButtonClick"
        >
          <small>ACCESS RESOURCE</small>
        </button> -->
        <a
          v-else
          class="button--purchase"
          :href="goToDownloadPage"
          @click="sendReportOfUserAccess"
        >
          <small>DOWNLOAD RESOURCE</small>
        </a>
      </template>
      <template #content>
        <LicenceCards
          :options="optionsLicenses"
          :fraud-text="fraudText"
          :terms-text="termsText"
          :is-free-licence="true"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'

import LicenceCards from '../licence/LicenceCards.vue'
import DropdownLicense from '../dropdown/DropdownLicense.vue'
import DownloadPrompt from '../download/DownloadPrompt.vue'
import IconExternalLink from '../icons/IconExternalLink.vue'
import Modal from './ComponentModal.vue'

import objectToQuerystring from '../../functions/objectToQuerystring'
import trackDownloadGA from '../../ga.js'

export default {
  name: 'ModalLicence',

  components: {
    DropdownLicense,
    IconExternalLink,
    LicenceCards,
    Modal
  },

  provide () {
    return {
      // Injecting this in LicenceCard which is a grandchild of this component
      productId: this.productId
    }
  },

  props: {
    product: {
      type: Object,
      default: () => ({})
    },

    licence: {
      type: Object,
      default: () => ({})
    },

    optionsLicenses: {
      type: Array,
      default: () => []
    },

    optionsVersions: {
      type: Array,
      default: () => []
    },

    fraudText: {
      required: true,
      type: String
    },

    termsText: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    goToDownloadPage () {
      const params = {
        option: this.product.license_id,
        endpoint_id: this.$store.state.resource.version
      }

      return `${window.location.origin}/products/${this.product.id}/access?${objectToQuerystring(params)}`
    },

    endpoint () {
      switch (true) {
        case this.isPlatform:
          return this.platformEndpoint
        case this.isGeoSpatial:
          return this.geospatialEndpoint
        default:
          return ''
      }
    },

    platformEndpoint () {
      const { id } = this.product
      const { version: endpointId} = this.$store.state.resource.version

      return `${window.location.origin}/products/${id}/download/${endpointId}`
    },

    geospatialEndpoint () {
      return `https://data-gis.unep-wcmc.org/portal/home/item.html?id=${this.productId}`
    },

    productId () {
      // Documents and Products have their IDs stored in different parts of this.product
      return Object.prototype.hasOwnProperty.call(this.product, 'json')
        ? this.product.json.id
        : this.product.id
    },

    resourceType () {
      return Object.prototype.hasOwnProperty.call(this.product, 'json')
        ? this.product.json.resource_type
        : this.product.resource_type
    },

    isPlatform () {
      if ('resource_type' in this.product) {
        return this.product.resource_type.toLowerCase() == 'platform'
      } else {
        return false
      }
    },

    isGeoSpatial () {
      if ('json' in this.product) {
        return this.product.json.resource_type.toLowerCase() == 'geospatial dataset'
      } else {
        return false
      }
    }
  },

  mounted () {
    this.$eventHub.$on('licence:selected', this.changeToDownload)
  },

  methods: {
    onOpenModalButtonClick () {
      this.setIsActive(true)
    },

    onAccessResourceClick () {
      trackDownloadGA(this.productId, this.resourceType)
    },

    setIsActive (isActive) {
      this.isActive = isActive === true
    },

    updateOptions (versionSelected) {
      this.$store.dispatch('resource/updateVersion', versionSelected)
    },

    sendReportOfUserAccess () {
      axios.post(`/products/${this.productId}/access`)
    }
  }
}
</script>
