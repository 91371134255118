<template>
  <div class="navbar__logo-section">
    <a
      class="navbar__logo-link"
      href="/"
    >
      <span class="navbar__logo-svg logo--wcmc">
        <logo-wcmc />
      </span>
      
      <h4 
        class="navbar__logo-callout"
        v-text="logoCalloutText"
      />
    </a>
  </div>
</template>

<script>
import LogoWcmc from "../../logos/LogoWcmc"

export default {
  name: "NavbarLogoSection",

  components: {
    LogoWcmc
  },

  data () {
    return {
      logoCalloutText: 'RESOURCES'
    }
  }
};
</script>
