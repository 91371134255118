<template>
  <tr class="table--filterable__row">
    <div class="table--filterable__result">
      <table-filterable-cell
        v-for="header in actualHeaders"
        :key="header.name"
        :is-mobile="isMobile"
        :is-tablet="isTablet"
        :header="header.name"
      >
        <template #content>
          <p class="table--filterable__value">
            {{ getRequiredValueOfItem(header) }}
          </p>
          <div
            v-if="shouldRenderExpirationAlert(item, header)"
            class="icon--exclamation-mark"
          >
            <icon-exclamation-mark />
          </div>
        </template>
      </table-filterable-cell>
      <td>
        <a
          v-show="item.expired === false"
          :href="linkToResource"
          @mouseenter="hover = true"
          @mouseleave="hover = false"
        ><small class="table--filterable__view-link">VIEW
          <div
            class="icon--right-arrow"
            :class="{ active: hover }"
          >
            <icon-right-arrow />
          </div>
        </small>
        </a>

        <!-- TODO - need to provide a link to renewing the resource directly (perhaps through query params) -->
        <a
          v-show="item.expired === true"
          :href="linkToRenewResource"
          @mouseenter="hover = true"
          @mouseleave="hover = false"
        ><small class="table--filterable__view-link expired">RENEW
          <div
            class="icon--right-arrow"
            :class="{ active: hover }"
          >
            <icon-right-arrow />
          </div>
        </small>
        </a>
      </td>
    </div>
  </tr>
</template>

<script>
import IconExclamationMark from '../icons/IconExclamationMark'
import IconRightArrow from '../icons/IconRightArrow'
import TableFilterableCell from './TableFilterableCell'

import { toLowerSnakeCase } from '../../helpers/text-helpers'

export default {
  name: 'TableFilterableResult',
  components: {
    IconExclamationMark,
    IconRightArrow,
    TableFilterableCell
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    headers: {
      type: Array,
      default: () => []
    },

    isMobile: Boolean,

    isTablet: Boolean
  },

  data () {
    return {
      hover: false,
      actualHeaders: undefined
    }
  },

  computed: {
    linkToResource () {
      return `/products/${this.item.id}`
    },

    linkToRenewResource () {
      return `/products/${this.item.id}&renew=true`
    }
  },

  mounted () {
    // Strip out the dummy header
    this.actualHeaders = this.headers.filter(header => header.name !== '')
  },

  methods: {
    getRequiredValueOfItem (header) {
      const foundPair = Object.entries(this.item).find((kvPair) => {
        const key = kvPair[0]

        if (key === toLowerSnakeCase(header.name)) {
          return kvPair
        }
      })

      return foundPair[1]
    },

    shouldRenderExpirationAlert (item, header) {
      return item.expired === true && header.name === 'EXPIRY DATE'
    }
  }
}
</script>
