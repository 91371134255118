
import Vue from 'vue'

// avatar
import Avatar from './components/avatar/ComponentAvatar'

// card
import CardCarousel from './components/card/CardCarousel'
import CardTheme from './components/card/CardTheme'

// copy
import CopyTrigger from './components/copy/CopyTrigger'

// carousel
import VCarousel from './components/carousel/VCarousel'

import DashboardTable from './components/table/DashboardTable'

// dropdown
import DropdownLicense from './components/dropdown/DropdownLicense'

// filterable table
import FilterableTable from '@unep-wcmc/wcmc-components'

// flashes
import FlashMessage from './components/flashes/FlashMessage'

// form
import PasswordField from './components/form/PasswordField'

// hero
import HeroSmall from './components/hero/HeroSmall'

// icons
import IconSearch from './components/icons/IconSearch'

// modal
import ModalErrors from './components/modal/ModalErrors'
import ModalLicence from './components/modal/ModalLicence'
import ModalTerms from './components/modal/ModalTerms'
import ModalMetadata from './components/modal/ModalMetadata'

// navbar
import Navbar from './components/navbar/Navbar'

// product
import ProductCard from './components/product/ProductCard'
import ProductImage from './components/product/ProductImage.vue'

// search
import SearchBar from './components/search/SearchBar'

// search_results
import SearchResultsPage from './components/search_results/SearchResultsPage'

// table
import TableFilterable from './components/table/TableFilterable'

// tabs
// import TabTarget from './components/tabs/TabTarget'

import VueMobileDetection from 'vue-mobile-detection'

// store
import store from './store/store.js'
Vue.use(FilterableTable, { store })

import axios from 'axios'
import { setAxiosHeaders } from './helpers/axios-helpers'

// cookieconsent
import 'cookieconsent'
import 'cookieconsent/build/cookieconsent.min.css'

export const eventHub = new Vue()
Vue.use(VueMobileDetection)
setAxiosHeaders(axios)

Vue.prototype.$http = axios

document.addEventListener('DOMContentLoaded', () => {
  Vue.prototype.$eventHub = new Vue()

  const app = new Vue({
    el: '#v-app',
    store,
    components: {
      Avatar,
      CardCarousel,
      CardTheme,
      CopyTrigger,
      DashboardTable,
      DropdownLicense,
      FlashMessage,
      HeroSmall,
      IconSearch,
      ModalErrors,
      ModalLicence,
      ModalMetadata,
      ModalTerms,
      Navbar,
      PasswordField,
      ProductCard,
      ProductImage,
      SearchBar,
      SearchResultsPage,
      TableFilterable,
      // TabTarget,
      VCarousel
    }
  })
})
