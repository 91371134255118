<template>
  <div class="navbar">
    <template v-if="!isLoginPage && !isSignupPage">
      <div class="navbar__sub-header">
        <div class="container--basic">
          <a
            class="navbar__sub-header-link"
            href="https://www.unep-wcmc.org"
          >
            <small> MAIN UNEP-WCMC WEBSITE </small>
          </a>
        </div>
      </div>
    </template>

    <div class="navbar__main-header">
      <transition
        name="fade"
        mode="out-in"
      >
        <component
          :is="activeComponent"
          v-bind="currentProperties"
          @search:active="toggleSearchOn"
          @search:inactive="toggleSearchOff"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import NavbarSearch from './NavbarSearch'
import NavbarStandard from './NavbarStandard'

export default {
  name: 'Navbar',

  components: {
    NavbarSearch,
    NavbarStandard
  },

  props: {
    donation: {
      type: Object,
      default: () => ({}),
    },

    links: {
      type: Array,
      default: () => ([])
    },

    isLoginPage: {
      type: Boolean,
      required: true
    },

    isSignupPage: {
      type: Boolean,
      required: true
    },

    user: {
      type: Object,
      default: () => ({}),
    },
  },

  data () {
    return {
      searchActive: false
    }
  },
  computed: {
    activeComponent() {
      return this.searchActive ? 'NavbarSearch' : 'NavbarStandard'
    },

    currentProperties () {
        if (this.activeComponent === 'NavbarStandard') {
          return {
            donation: this.donation,
            links: this.links,
            isLoginPage: this.isLoginPage,
            isSignupPage: this.isSignupPage,
            user: this.user
          }
        } else {
          return {}
      }
    }
  },

  methods: {
    toggleSearchOn() {
      this.searchActive = true
    },

    toggleSearchOff() {
      this.searchActive = false
    }
  },
}
</script>