<template>
  <a
    class="search-result"
    :href="`/products/${id}`"
    target="_blank"
  >
    <ProductImage
      css-class="search-result__image"
      :is-placeholder="genericImage"
      :src="thumbnailUrl"
    />

    <div class="search-result__body">
      <div class="search-result__header">
        <h6
          class="search-result__resource-type"
          v-text="resourceType"
        />

        <span
          v-if="displayProteusBadge"
          class="search-result__proteus-badge"
          v-text="'PROTEUS'"
        />
      </div>

      <h3
        class="search-result__title"
        v-text="titleTrimmed"
      />

      <p
        class="search-result__description"
        v-html="descriptionTrimmed"
      />
    </div>
  </a>
</template>

<script>
import ProductImage from "../product/ProductImage.vue";
import mixinDetectWrap from "../../mixins/mixin-detect-wrap.js";

export default {
  name: "SearchResult",

  components: { ProductImage },

  mixins: [mixinDetectWrap],

  props: {
    description: {
      type: String,
      default: "",
    },

    id: {
      type: String,
      required: true,
    },

    displayProteusBadge: {
      type: Boolean,
      default: false,
    },

    genericImage: {
      type: Boolean,
      default: false,
    },

    resourceType: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    tags: {
      type: Array,
      default: () => [],
    },

    thumbnailUrl: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      hasWrappedItems: undefined,
    };
  },

  computed: {
    descriptionTrimmed() {
      const length = 100;

      return this.description.length <= length
        ? this.description
        : this.trim(this.description, length - 3);
    },

    titleTrimmed() {
      const length = 150;

      return this.title.length <= length
        ? this.title
        : this.trim(this.title, length - 3);
    },
  },

  // Still need to have the component check for wrapping when the page is initially loaded in
  mounted() {
    this.isWrapped(this.id);

    // Listen for window resize
    window.addEventListener("resize", this.isWrapped(this.id));
  },

  methods: {
    trim(string, length) {
      return string.substring(0, length) + "...";
    },

    isWrapped(id) {
      const wrappedItems = this.detectWrap(
        `#tags-${id} > .search-result__tag`,
        "search-result__tag--hidden"
      );

      if (wrappedItems.length > 0) {
        this.addClassToItem(wrappedItems, "search-result__tag--hidden");
        this.hasWrappedItems = true;
      } else {
        this.hasWrappedItems = false;
      }
    },
  },
};
</script>
