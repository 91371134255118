import vuescrollto from 'vue-scrollto'

export default {
  data () {
    return {
      options: {
        container: 'body',
        easing: 'ease-in',
        lazy: false,
        offset: 0,
        force: true,
        cancelable: true,
        x: false,
        y: true
      }
    }
  },
  methods: {
    scrollToElem (elem, duration) {
      vuescrollto.scrollTo(elem, duration, this.options)
    }
  }
}
