<template>
  <div class="table--filterable container">
    <template v-if="noResourceLicenses">
      <table-filterable-banner :text="bannerText" />
    </template>

    <template v-else-if="notLoading">
      <div class="table--filterable__filters">
        <template v-if="!isMobile">
          <filter-data
            v-for="(filter, index) in filters"
            :key="`filter-${index}`"
            :title="filter.title"
          />
        </template>

        <template v-else>
          <modal-filters :options="filters" />
        </template>
      </div>

      <table class="table--filterable__table">
        <tr class="table--filterable__headers">
          <th
            v-for="(header, index) in tableHeaders"
            :key="`header-${index}`"
            class="table--filterable__header"
          >
            <small class="table--filterable__header-text">
              {{ header.name }}
            </small>
          </th>
        </tr>

        <!-- No need to pass in (and therefore render) headers if the screen isn't mobile -->
        <table-filterable-result
          v-for="(item, key) in currentItems"
          :key="key"
          :item="item"
          :headers="tableHeaders"
          :is-tablet="isTablet"
          :is-mobile="isMobile"
        />

        <table-fake-row
          v-for="(item, index) in remnantItems"
          :key="`fake-row-${index}`"
        />
      </table>

      <pagination
        :pages="totalPages"
        :items-number="currentItems.length"
        :total-items="totalItems"
        @show:page="getNewItems"
      />
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import { setAxiosHeaders } from '../../helpers/axios-helpers'
import { formatDate } from '../../helpers/date-helpers'
import mixinScrollTo from '../../mixins/mixin-scroll-to'
import mixinResponsive from '../../mixins/mixin-responsive'

import Pagination from './../pagination/ComponentPagination'
import FilterData from '../filters/FilterData'
import ModalFilters from '../modal/ModalFilters'
import TableFakeRow from './TableFakeRow'
import TableFilterableResult from './TableFilterableResult'
import TableFilterableBanner from './TableFilterableBanner'

setAxiosHeaders(axios)

const TABLE_SIZE = 4

export default {
  name: 'TableFilterable',
  components: {
    Pagination,
    FilterData,
    ModalFilters,
    TableFakeRow,
    TableFilterableResult,
    TableFilterableBanner
  },

  mixins: [
    mixinResponsive,
    mixinScrollTo
  ],

  props: {
    filters: {
      type: Array,
      default: () => []
    },
    noDataText: {
      type: Object,
      default: () => {}
    },
    tableHeaders: {
      type: Array,
      default: () => ([])
    },
    endpointList: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      currentPage: 1,
      currentItems: [],
      filtersApplied: false,
      totalItems: 0,
      allUserItemCount: 0,
      totalPages: 0,
      bannerText: {},
      loading: true
    }
  },

  computed: {
    remnantItems () {
      return TABLE_SIZE - this.currentItems.length
    },

    isTablet () {
      return this.isMedium()
    },

    isMobile () {
      return this.isSmall() || this.isXSmall()
    },

    noResourceLicenses () {
      return !this.loading && this.currentItems.length === 0 && this.allUserItemCount === 0
    },

    notLoading () {
      return !this.loading
    }
  },

  mounted () {
    this.createSelectedFilterOptions()
    this.getNewItems(this.currentPage)
    if (this.currentItems.length === 0) { this.bannerText = this.noDataText }

    this.$eventHub.$on('getNewItems', this.getNewItems)
    this.$eventHub.$on('filtersApplied', this.notifyFiltersApplied)
  },

  methods: {
    getNewItems (page) {
      const data = {
        page,
        filters: this.$store.state.table.selectedFilterOptions
      }

      axios.post(this.endpointList, data)
        .then(response => {
          this.updateProperties(response.data)
          // Commit items to table store then update filters
          this.$store.dispatch('table/updateFilters', response.data.filterMetadata)
          this.loading = false
        })
        .catch(function (error) {
          console.log(error)
        })

      if (this.currentItems.length > 0) {
        this.scrollToElem('.table--filterable__headers', 500)
      }
    },

    updateProperties (data) {
      this.currentPage = data.metadata.page
      this.totalItems = data.metadata.count
      this.totalPages = data.metadata.pages
      this.allUserItemCount = data.metadata.original_count
      this.currentItems = this.computeExpiries(data.items)
    },

    computeExpiries (items) {
      return items.map((item) => {
        if (item.expiry_date === '' || item.expiry_date === undefined) {
          return { ...item, expired: false }
        }

        const correctlyFormattedDate = formatDate(item.expiry_date)

        if (correctlyFormattedDate <= new Date()) {
          return { ...item, expired: true }
        }

        return { ...item, expired: false }
      })
    },

    createSelectedFilterOptions () {
      const array = []

      // create an empty array for each filter
      this.filters.forEach(filter => {
        const obj = {}

        obj.title = filter.title
        obj.options = []

        array.push(obj)
      })

      this.$store.dispatch('table/setFilterOptions', array)
    },

    notifyFiltersApplied () {
      this.filtersApplied = true
    }
  }
}
</script>
