export const storeTable = {
  namespaced: true,

  state: {
    filters: {},
    requestedPage: 1,
    selectedFilterOptions: [] // an array containing an object for each filter that has an array of selected options
  },

  actions: {
    setFilterOptions ({ commit }, filterOptions) {
      commit('setFilterOptions', filterOptions)
    },
    updateFilters ({ commit }, filters) {
      commit('updateFilters', filters)
    },
    updateFilterOptions ({ commit }, newOptions) {
      commit('updateFilterOptions', newOptions)
    },
    updateRequestedPage ({ commit }, page) {
      commit('updateRequestedPage', page)
    }
  },

  mutations: {
    setFilterOptions (state, options) {
      state.selectedFilterOptions = options
    },
    updateFilters (state, payload) {
      state.filters = payload
    },
    updateFilterOptions (state, newOptions) {
      // find the correct filter to update
      state.selectedFilterOptions.forEach((filter) => {
        if (filter.title == newOptions.filter) {
          // replace filter options array with newOptions array
          filter.options = newOptions.options
        }
      })
    },
    updateRequestedPage (state, page) {
      state.requestedPage = page
    }
  },

  getters: {
    fetchFilters (state) {
      return state.filters
    }
  }
}
