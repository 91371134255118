<template>
  <li class="licence-card">
    <div class="licence-card__header">
      <h3 class="licence-card__title">
        {{ title }}
      </h3>
    </div>

    <div class="licence-card__body">
      <div class="licence-card__content">
        <slot />

        <div
          v-if="!hasExternalURL"
          class="licence-card__conditions"
        >
          <DownloadPrompt
            :fraud-text="fraudText"
            :terms-text="termsText"
            :is-free-licence="isFreeLicence"
            @terms-fraud:updated="updateTermsFraud"
            @terms-licence:updated="updateTermsLicence"
            @donation:updated="updateDonationOffered"
            @donation-amount:updated="updateDonationAmount"
          />
        </div>
      </div>
    </div>

    <a
      v-show="!donationOffered"
      style="display:block"
      :class="['button--purchase', {'button--disabled': !basicConditionsMet}]"
      :href="resourceAccessURL"
      :target="target"
    >
      Access
    </a>

    <button
      v-show="donationOffered"
      :class="['button--purchase', {'button--disabled': !donationConditionsMet }]"
      @click="donateAndAccessResource"
    >
      Donate and Access
    </button>

    <p
      v-if="error"
      ref="error"
      class="licence-card__error"
    >
      {{ error }}
    </p>
  </li>
</template>

<script>
import DownloadPrompt from '../download/DownloadPrompt.vue'
import objectToQuerystring from '../../functions/objectToQuerystring'

export default {
  name: 'LicenceCard',

  components: { DownloadPrompt },

  inject: {
    productId: { default: '' }
  },

  props: {
    externalAccessUrl: {
      type: String,
      default: ''
    },

    optionId: {
      type: Number,
      default: 0
    },

    licenseId: {
      type: Number,
      default: 0
    },

    title: {
      type: String,
      default: ''
    },

    isFreeLicence: Boolean,

    fraudText: {
      required: true,
      type: String
    },

    termsText: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      error: undefined,
      donationAmount: 0,
      donationOffered: false,
      termsFraudAccepted: false,
      termsLicenceAccepted: false
    }
  },

  computed: {
    hasExternalURL () {
      return this.externalAccessUrl != null
    },

    /**
     * Generate the resource access URL
     * that should contain contain information about the resource
     */
    resourceAccessURL () {
      const params = {
        option: this.optionId,
        endpoint_id: this.$store.state.resource.version
      }

      return `${window.location.origin}/products/${this.productId}/access?${objectToQuerystring(params)}`
    },

    basicConditionsMet () {
      if (this.hasExternalURL) {
        return true
      } else {
        return this.termsLicenceAccepted
      }
    },

    donationConditionsMet () {
      const
        isDonating = this.donationOffered
      const hasDonationAmount = this.donationAmount > 0
      const termsAccepted = this.termsLicenceAccepted && this.termsFraudAccepted

      return termsAccepted && isDonating && hasDonationAmount
    },

    target () {
      return this.hasExternalURL ? '_blank' : '_self'
    }
  },

  watch: {
    error (error) {
      if (error) {
        this.$nextTick(() => {
          this.$refs.error.scrollIntoView()
        })
      }
    }
  },

  methods: {
    donateAndAccessResource () {
      this.setError(undefined)

      const payload = {
        donation_amount: parseInt(this.donationAmount),
        license_option_id: this.optionId,
        product_id: this.productId
      }

      this.$http.post('/payments/create.json', payload)
        .then(response => {
          const { data: { session_id, publishable_key } } = response

          this.$root.$emit('stripe:redirect-to-checkout', {
            options: {
              sessionId: session_id
            },
            publishableKey: publishable_key
          })
        })
        .catch(error => {
          if (error.response && error.response.data &&
            error.response.data.error) {
            this.setError(error.response.data.error)
          } else {
            this.setError(error.message)
          }
          console.error({ error })
        })
    },

    setError (error = undefined) {
      this.error = error
    },

    updateTermsFraud (boolean) {
      this.termsFraudAccepted = boolean === true
    },

    updateTermsLicence (boolean) {
      this.termsLicenceAccepted = boolean === true
    },

    updateDonationOffered (boolean) {
      this.donationOffered = boolean === true
    },

    updateDonationAmount (value) {
      this.donationAmount = value
    }

    // dispatchToStore() {
    //   const payload = {
    //     licenceOptionId: this.optionId,
    //     licenceId: this.licenseId,
    //     id: this.productId
    //   }

    //   this.$store.dispatch('resource/updateResource', payload)
    //   this.$eventHub.$emit('licence:selected')
    // },
  }
}
</script>
