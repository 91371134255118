<template>
  <li class="filter--search">
    <div class="filter--search__title">
      <p :text="filterName.toUpperCase()" />

      <button
        class="button--search__toggle"
        :class="{ active: isActive }"
        @click="toggleFilter"
      />
    </div>

    <div class="filter--search__gradient" :class="{ scrollable: hasScrolled }">
      <ul v-if="isActive" ref="filter_panel" class="filter--search__values">
        <filter-option
          v-for="(value, index) in filterResults"
          :key="index"
          :filter-option="value"
          :is-pre-selected="isPreSelected(value)"
          @filter:select="applyFilterOption"
          @filter:deselect="removeFilterOption"
        />
      </ul>
    </div>

    <div v-if="filter.searchable" class="filter--search__search-bar">
      <input
        v-model="searchTerm"
        class="filter--search__search-box"
        :placeholder="`Search ${filtername}`"
      />
      <i class="icon--search-black" />
    </div>
  </li>
</template>

<script>
import mixinDetectScroll from "../../mixins/mixin-detect-scroll";
import FilterOption from "./FilterOption";

export default {
  name: "FilterSearch",

  components: {
    FilterOption,
  },

  mixins: [mixinDetectScroll],

  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      filterName: Object.keys(this.filter)[0],
      filterValues: Object.values(this.filter),
      isActive: true,
      hasScrolled: undefined,
      searchTerm: undefined,
      checkedFilters: {
        filterType: Object.keys(this.filter)[0],
        filters: [],
      },
      preSelected: "",
    };
  },

  computed: {
    filtername() {
      return this.filterName.toLowerCase();
    },

    filterResults() {
      if (!this.searchTerm) {
        return this.filterValues[0];
      }

      let autocompleteOptions = this.filterValues[0];

      if (this.searchTerm !== "") {
        const regex = new RegExp(`${this.searchTerm}`, "i");

        autocompleteOptions = autocompleteOptions.filter((option) => {
          return option.match(regex);
        });
      }

      return autocompleteOptions;
    },
  },

  watch: {
    checkedFilters: {
      handler: function () {
        this.$store.dispatch("search/updateFilters", this.checkedFilters);
        this.$root.$emit("update:filters");
      },
      deep: true,
    },
  },

  created() {
    this.preSelected =
      "preSelected" in this.filter ? this.filter.preSelected : "";
  },

  mounted() {
    // Apply the light grey gradient if number of options exceeds max height
    this.hasScrolled = this.checkScroll(this.$refs.filter_panel);
  },
  created() {
    this.preSelected =
      "preSelected" in this.filter ? this.filter.preSelected : "";
  },
  mounted() {
    // Apply the light grey gradient if number of options exceeds max height
    this.hasScrolled = this.checkScroll(this.$refs.filter_panel);
  },

  methods: {
    applyFilterOption(value) {
      this.checkedFilters.filters.push(value);
    },

    isPreSelected(value) {
      return value.toLowerCase() == this.preSelected;
    },

    removeFilterOption(value) {
      const index = this.checkedFilters.filters.indexOf(value);
      if (index > -1) {
        this.checkedFilters.filters.splice(index, 1);
      }
    },

    toggleFilter() {
      this.isActive = !this.isActive;
    },
  },
};
</script>
