<template>
  <div class="form-donate__input-group">
    <input
      id="other_amount"
      type="text"
      name="donation_amount"
      placeholder="Enter amount"
      class="form-donate__input"
      v-bind="{ value }"
      @keydown="onKeydown"
      @keyup="onKeyup"
    >
    <span class="form-donate__currency-symbol">£</span>
  </div>
</template>

<script>
const PERMITTED_KEYS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Enter',
  'Tab'
]

export default {
  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    value: {
      type: [Number, String],
      default: undefined
    }
  },

  emits: [
    'input'
  ],

  methods: {
    onKeydown (e) {
      if (!PERMITTED_KEYS.includes(e.key)) {
        e.preventDefault()
      }
    },

    onKeyup (e) {
      const value = parseInt(e.target.value)

      this.$emit('input', value || undefined)
    }
  }
}
</script>
