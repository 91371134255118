import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex/dist/vuex.esm'

// import { storePaymentSession } from './_store-payment.js'
import { storeResource } from './_store-resource.js'
import { storeSearch } from './_store-search.js'
import { storeTable } from './_store-table.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // payment: storePaymentSession,
    resource: storeResource,
    search: storeSearch,
    table: storeTable
  }
})
