<template>
  <div class="search-bar">
    <div class="search-bar__box">
      <div class="search-bar__input">
        <div class="icon--search">
          <icon-search />
        </div>

        <input
          v-model="searchTerm"
          placeholder="Search for a resource"
          type="text"
          @keyup.enter="search"
        >
      </div>

      <button
        class="button--blue-search"
        @click="search"
      >
        <small
          v-if="textButton"
          v-text="textButton"
        />

        <icon-search v-else />
      </button>
    </div>
  </div>
</template>

<script>
import IconSearch from '../icons/IconSearch'

export default {
  name: 'SearchBar',

  components: {
    IconSearch
  },

  props: {
    endpoint: {
      type: String,
      default: ''
    },

    existingSearchTerm: {
      type: String,
      default: ''
    },

    textButton: {
      type: String,
      default: ''
    }
  },

  emits: [
    'update:search-term'
  ],

  data () {
    return {
      searchTerm: ''
    }
  },

  computed: {
    isSearchTermAbsent () {
      const { existingSearchTerm: searchTerm } = this

      return (typeof searchTerm !== 'string' || searchTerm.length < 1)
    }
  },

  created () {
    this.$root.$on('reset:search', this.reset)
  },

  mounted () {
    if (this.isSearchTermAbsent) { return }

    this.searchTerm = this.existingSearchTerm
    this.search()
  },

  methods: {
    reset () {
      this.searchTerm = ''
    },

    search () {
      this.$root.$emit('reset:page') // New search resets any pagination

      if (this.endpoint) {
        window.location.href = `${this.endpoint}?search-term=${this.searchTerm}`
      } else {
        this.updateSearchTerm()
      }
    },

    updateSearchTerm () {
      this.$store.dispatch('search/search', this.searchTerm)
      this.$emit('update:search-term')
    }
  }
}
</script>
