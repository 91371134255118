<template>
  <div
    v-if="hasMultipleOptions"
    class="dropdown--license"
  >
    <div
      class="dropdown--license__toggle"
      @click="toggleDropdown"
    >
      <span 
        class="dropdown--license__toggle-selected"
        v-text="selectedOption.filename"
      />

      <span
        class="dropdown--license__toggle-icon icon--drop-arrow"
        :class="{ active: isActive }"
      >
        <icon-drop-arrow />
      </span>
    </div>

    <ul
      v-if="isActive"
      class="dropdown--license__options"
    >
      <li
        v-for="option in options"
        :key="option"
        class="dropdown--license__option"
        @click="selectOption(option)"
      >
        <div
          class="dropdown--license__checkbox"
          :class="{active: option == selectedOption}"
        />

        <p
          v-text="option.filename"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import mixinPopupCloseListeners from '../../mixins/mixin-popup-close-listeners'

import IconDropArrow from '../icons/IconDropArrow'

export default {
  name: 'DropdownLicense',

  components: {
    IconDropArrow
  },

  mixins: [mixinPopupCloseListeners({ closeCallback: 'toggleDropdown', toggleVariable: 'isActive' })],

  props: {
    options: {
      type: Array,
      required: true
    }
  },

  emits: [
    'version:updated'
  ],

  data () {
    return {
      isActive: false,
      selectedOption: {}
    }
  },

  computed: {
    hasOptions () {
      return this.options.length > 0
    },

    hasMultipleOptions () {
      return this.options.length > 1
    }
  },
  mounted () {
    // make sure store is updated when there is only one
    // version and the user is unlikely to click
    if (this.hasOptions) {
      this.selectedOption = this.options[0]
      this.selectOption(this.selectedOption)
    }
  },
  methods: {
    closeDropdown () {
      this.isActive = false
    },
    toggleDropdown () {
      this.isActive = !this.isActive
    },
    selectOption (option) {
      this.selectedOption = option
      this.$emit('version:updated', this.selectedOption.endpoint_id)

      this.closeDropdown()
    }
  }
}
</script>
