<template>
  <!-- TODO - work on this component is blocked because of the licence workflow revamping the modals -->
  <!-- Resume work on this after that workflow is complete -->
  <div>
    <button
      class="button--filters-mobile"
      @click="onOpenModalButtonClick"
    />
    <div
      v-if="isActive"
      class="filters-mobile"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalFilters',

  props: {
    options: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  methods: {
    onOpenModalButtonClick () {
      this.setIsActive(true)
    },

    setIsActive (isActive) {
      this.isActive = isActive === true
    }
  }
}
</script>
