<template>
  <div class="container--between">
    <!-- NAVBAR LOGO SECTION -->
    <navbar-logo-section />

    <div class="navbar__links">
      <template v-if="displayLinks">
        <ul class="navbar__links-list">
          <a
            class="navbar__link login"
            href="/search"
            v-text="'EXPLORE RESOURCES'"
          />

          <template v-if="isUserPresent">
            <a
              class="navbar__link login"
              href="/account/dashboard"
              v-text="'MY DASHBOARD'"
            />

            <span
              class="button--black navbar__link login"
              @click="sendLogoutRequest"
            >
              LOG OUT
            </span>

            <avatar class="navbar__link" :user="user" />
          </template>

          <template v-else>
            <a
              class="button--black navbar__link login"
              href="/user/sessions/new"
            >
              LOG IN
            </a>
          </template>

          <!-- <a
            v-if="canRenderDonationButton"
            class="navbar__button-donation"
            :title="donation.titleAttr"
            :href="donation.href"
            target="_blank"
            v-text="donation.text"
          /> -->

          <button
            v-if="user"
            type="button"
            class="icon--search navbar__link"
            @click="toggleSearchOn"
          >
            <icon-search />

            <span class="visually-hidden">Site search</span>
          </button>

          <modal-menu :links="links" />
        </ul>
      </template>

      <template v-else-if="isLoginPage">
        <a href="/user/new">
          <small class="white"> SIGN UP </small>
        </a>
      </template>

      <template v-else-if="isSignupPage">
        <a href="/users/sign_in">
          <small class="white"> LOG IN </small>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { setAxiosHeaders } from '../../helpers/axios-helpers'

import Avatar from "../avatar/ComponentAvatar";
import IconSearch from "../icons/IconSearch";
import ModalMenu from "../modal/ModalMenu";
import NavbarLogoSection from "../navbar/sections/NavbarLogoSection";
import { constructLink } from "../../helpers/link-helpers";

export default {
  name: "NavbarStandard",

  components: {
    Avatar,
    IconSearch,
    ModalMenu,
    NavbarLogoSection,
  },

  props: {
    donation: {
      type: Object,
      default: () => {},
    },

    links: {
      type: Array,
      default: () => [],
    },

    isLoginPage: {
      type: Boolean,
      default: false,
    },

    isSignupPage: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
      default: () => {},
    },
  },

  emits: ["search:active"],

  computed: {
    navbarLinksVariantClass() {
      const standardClass = "navbar__links-list";

      const variation = `--logged${this.user ? "-in" : "-out"}`;
      const variantClass = standardClass + variation;

      return variantClass;
    },
    
    displayLinks () {
      return !(this.isLoginPage || this.isSignupPage);
    },

    canRenderDonationButton() {
      const { titleAttr, href, text } = this.donation;

      return titleAttr && href && text;
    },

    isUserPresent () {
      return Object.keys(this.user).length > 0
    }
  },

  methods: {
    toggleSearchOn () {
      this.$emit("search:active");
    },

    isCurrentPage(linkSlug) {
      return (
        window.location.pathname.concat(window.location.search) ==
        this.constructLink(linkSlug)
      );
    },

    sendLogoutRequest () {
      setAxiosHeaders(axios)

      axios
        .delete('/user/sessions')
        .then(() => { window.location.href = '/' })
    },

    constructLink,
  },
};
</script>