<template>
  <div class="container dashboard">
    <h3 class="dashboard__title">
      ACCESS HISTORY
    </h3>

    <slot />
  </div>
</template>

<script>
export default {
  name: "DashboardTable",
};
</script>

<style lang="scss">
.filters {
  display: none !important;
}

.table-head .cell {
  background-color: white !important;

  .title {
    color: black !important;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.table-body .row {
  --border-width: 0 !important;
  background-color: #F4F4F4 !important;
  margin-bottom: 12px !important;

  .cell {
    align-items: center;
    background-color: transparent !important;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .view-link {
    text-transform: uppercase;
    color: black !important;
  }

  .proteus-badge {
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 20px;
    border: 2px solid rgb(139, 111, 175);
    background-color: rgba(139, 111, 175, 0.5);
    padding: 10px 8px;
  }

  .update-available-badge {
    margin-left: 10px;
  }
}

// This is a very hacky way of thickening the font in the resource title column
.cell.cell--resource-title {
  font-weight: 700;
}

// A hacky way of selecting the column with links, which is the right-most column currently
.cell:last-child {
  justify-content: flex-end;
}

</style>