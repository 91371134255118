<template>
  <div>
    <h3 class="modal__title license">
      Select a Licence
    </h3>
    <ul class="licence-cards">
      <licence-card
        v-for="option in options"
        :key="option.id"
        :external-access-url="option.external_access_url"
        :option-id="option.id"
        :license-id="option.license_id"
        :title="option.title"
        :fraud-text="fraudText"
        :terms-text="termsText"
        :is-free-licence="isFreeLicence"
      >
        <licence-data
          :content="option.card_contents"
        />
      </licence-card>
    </ul>
  </div>
</template>

<script>
import LicenceCard from './LicenceCard.vue'
import LicenceData from './LicenceData.vue'

export default {
  name: 'LicenceCards',

  components: {
    LicenceData,
    LicenceCard
  },

  props: {
    options: {
      type: Array,
      default: () => []
    },

    isFreeLicence: Boolean,

    fraudText: {
      required: true,
      type: String
    },

    termsText: {
      required: true,
      type: String
    }
  }
}
</script>
