<template>
  <div class="search-results">
    <div
      v-if="!resultsLoaded"
      class="search-results__overlay" 
    >
      <div class="search-results__overlay-text">
        <h2
          v-text="'Fetching results...'"
        />

        <div class="icon--loading-spinner">
          <icon-spinner />
        </div>
      </div>
    </div>

    <template v-if="noResultsLoaded">
      <div class="search-results__no-results">
        <h2 v-text="'No results found.'" />

        <p v-text="'Please try again.'" />
      </div>
    </template>

    <template v-else>
      <search-result
        v-for="(result, index) in results"
        :id="result.id"
        :key="index"
        :resource-type="result.resource_type.toUpperCase()"
        :title="result.title"
        :description="result.short_description"
        :tags="result.tags"
        :thumbnail-url="result.url"
        :generic-image="result.generic_image"
        :display-proteus-badge="result['display_proteus_badge?']"
      />
    </template>
  </div>
</template>

<script>
import IconSpinner from '../icons/IconSpinner'
import SearchResult from './SearchResult'

export default {
  name: 'SearchResults',

  components: {
    IconSpinner,
    SearchResult
  },

  props: {
    results: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      resultsLoaded: false
    }
  },

  computed: {
    noResultsLoaded () {
      const resultsPresent = this.results && this.results.length > 0
      return !resultsPresent && this.resultsLoaded
    }
  },

  mounted () {
    this.$root.$on('results:loaded', this.setResults)
    this.$root.$on('reset:page', this.loadResults)
    this.$root.$on('results:update', this.loadResults)
  },

  methods: {
    setResults () {
      this.resultsLoaded = true
    },

    loadResults () {
      this.resultsLoaded = false
    }
  }
}
</script>
