<template>
  <div>
    <modal v-model="isActive">
      <template #button>
        <p
          @click="onOpenModalButtonClick"
          v-text="termsAcceptance"
        />
      </template>
      <template #content>
        <h2>Conservation Knowledge Portal</h2>
        <h3 v-text="welcomeText" />
        <p v-text="text" />
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './ComponentModal'

export default {
  name: 'ModalTerms',

  components: {
    Modal
  },

  props: {
    welcomeText: {
      type: String,
      default: 'Terms and Conditions'
    },

    termsAcceptance: {
      type: String,
      default: 'I agree to the terms and conditions'
    },

    text: {
      type: String,
      default: `
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
        Soluta blanditiis earum odio dolore deleniti pariatur repellendus, dolor
         consequuntur alias quidem in accusantium inventore debitis laboriosam 
         voluptatibus ipsam placeat natus quaerat?
        `
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  methods: {
    onOpenModalButtonClick () {
      this.setIsActive(true)
    },

    setIsActive (isActive) {
      this.isActive = isActive === true
    }
  }
}
</script>
