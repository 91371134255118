<template>
  <div>
    <slot />
    <span
      :class="[visible ? 'icon--visible' : 'icon--invisible' ]"
      @click="toggleVisibility"
    >
      <component :is="currentIcon" />
    </span>
  </div>
</template>

<script>
import IconInvisible from '../icons/IconInvisible'
import IconVisible from '../icons/IconVisible'

export default {
  name: 'PasswordField',

  components: {
    IconInvisible,
    IconVisible
  },

  data () {
    return {
      components: ['IconVisible', 'IconInvisible'],
      visible: false
    }
  },

  computed: {
    currentIcon () {
      return this.visible === true ? 'IconVisible' : 'IconInvisible'
    }
  },
  methods: {
    toggleVisibility () {
      this.visible = !this.visible

      const password = this.$slots.default()[0].elm
      const type = password.getAttribute('type')

      const newType = type === 'password' ? 'text' : 'password'

      password.setAttribute('type', newType)
    }
  }
}
</script>
