<template>
  <div class="carousel">
    <div class="carousel__header">
      <h2
        class="carousel__header__text"
        v-text="headerText"
      />

      <CarouselNavigation
        v-if="hasEnoughSlides"
        @next="next()"
        @previous="previous()"
      />
    </div>

    <Flickity
      ref="flickity"
      :options="flickityOptions"
      class="carousel__frame"
    >
      <slot :cards="cards" />
    </Flickity>
  </div>
</template>

<script>
import CarouselNavigation from './CarouselNavigation'

import Flickity from 'vue-flickity'
import mixinResponsive from '../../mixins/mixin-responsive'

export default {
  name: 'CarouselFlickity',

  components: {
    CarouselNavigation,
    Flickity
  },

  mixins: [
    mixinResponsive
  ],

  props: {
    cards: {
      type: Array,
      default: () => ([])
    },

    title: {
      type: String,
      required: true
    },

    shortTitle: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      flickityOptions: {
        cellAlign: 'left',
        initialIndex: 0,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true
      }
    }
  },

  computed: {
    hasEnoughSlides () {
      return this.cards.length > 3
    },

    useShortTitle () {
      if (this.shortTitle == null) { return false }

      return [
        'xsmall',
        'small'
      ].includes(this.getCurrentBreakpoint())
    },

    headerText () {
      return (
        this.useShortTitle
          ? this.shortTitle
          : this.title
      )
    }
  },

  created () {
    this.cards.map(option => {
      option.image = new Image()
      option.image.src = option.url

      return option
    })
  },

  methods: {
    next () {
      this.$refs.flickity.next()
    },

    previous () {
      this.$refs.flickity.previous()
    }
  }
}
</script>
