<template>
  <p :class="`flash--${type}`">
    <span class="flash__icon">
      <slot />
    </span>
    <span>{{ value }}</span>
    <span
      v-if="close"
      class="flash__close"
      @click.stop="closeMessage"
    >×</span>
  </p>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    close: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    closeMessage () {
      this.$el.style.display = 'none'
    }
  }
}
</script>
