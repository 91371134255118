export const storeResource = {
  namespaced: true,

  state: {
    // id: undefined,
    // licenceId: undefined,
    // licenceOptionId: undefined,
    version: undefined
    // donationAmount: 0
  },

  actions: {
    // updateDonationAmount({ commit }, value) {
    //   commit('updateDonationAmount', value)
    // },
    // updateResource({ commit }, option) {
    //   commit('updateId', option.id)
    //   commit('updateLicenceId', option.licenceId)
    //   commit('updateLicenceOptionId', option.licenceOptionId)
    // },
    updateVersion ({ commit }, version) {
      commit('updateVersion', version)
    }
  },

  mutations: {
    // updateDonationAmount(state, value) {
    //   state.donationAmount = value
    // },
    // updateId(state, id) {
    //   state.id = id
    // },
    // updateLicenceId(state, licenceId) {
    //   state.licenceId = licenceId
    // },
    // updateLicenceOptionId(state, licenceOptionId) {
    //   state.licenceOptionId = licenceOptionId
    // },
    updateVersion (state, version) {
      state.version = version
    }
  }
}
