<template>
  <div>
    <CheckboxWithLabel
      id="terms-licence"
      checkbox-colour="white"
      :text="termsText"
      @checked:terms-licence="toggleLicenceAgreement"
    />

    <label class="form-spacing-small">
      <small>Would you like to make a donation?</small>
    </label>

    <div
      class="form-spacing-small"
      :class="{'last-item-visible': isDonationRadioLastItemVisible}"
    >
      <label class="radio-label">
        <input
          id="radio-yes"
          v-model="donationOffered"
          type="radio"
          :value="true"
        >
        Yes
      </label>

      <label class="radio-label">
        <input
          id="radio-no"
          v-model="donationOffered"
          type="radio"
          :value="false"
        >
        No
      </label>
    </div>

    <div v-show="donationOffered">
      <div class="form-spacing-small">
        <FormDonateCustomAmount
          v-model="donationAmount"
          @input="updateDonationAmount"
        />
      </div>

      <div class="form-spacing-small">
        <CheckboxWithLabel
          id="terms-fraud"
          checkbox-colour="white"
          :text="fraudText"
          @checked:terms-fraud="toggleFraudAgreement"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxWithLabel from '../checkbox/CheckboxWithLabel'
import FormDonateCustomAmount from '../form/DonateCustomAmount'

export default {
  name: 'DownloadPrompt',

  components: {
    CheckboxWithLabel,
    FormDonateCustomAmount
  },

  props: {
    isFreeLicence: Boolean,

    fraudText: {
      type: String,
      default: ''
    },

    termsText: {
      type: String,
      default: ''
    }
  },

  emits: [
    'donation:updated',
    'donation-amount:updated',
    'terms-fraud:updated',
    'terms-licence:updated'
  ],

  data () {
    return {
      termsLicenceAccepted: false,
      termsFraudAccepted: false,
      resource: undefined,
      donationOffered: false,
      donationAmount: 0
    }
  },

  computed: {
    isDonationRadioLastItemVisible () {
      return !this.donationOffered
    }
  },

  watch: {
    donationOffered () {
      this.$emit('donation:updated', this.donationOffered)
    }
  },

  mounted () {
    this.resource = this.$store.state.resource.id
  },

  methods: {
    updateDonationAmount () {
      this.$emit('donation-amount:updated', this.donationAmount)
    },

    toggleFraudAgreement () {
      this.termsFraudAccepted = !this.termsFraudAccepted
      this.$emit('terms-fraud:updated', this.termsFraudAccepted)
    },

    toggleLicenceAgreement () {
      this.termsLicenceAccepted = !this.termsLicenceAccepted
      this.$emit('terms-licence:updated', this.termsLicenceAccepted)
    }
  }
}
</script>
