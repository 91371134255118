export default {
  methods: {
    detectWrap (className, extraClass) {
      const wrappedItems = []
      let prevItem = {}
      let currItem = {}
      const items = document.querySelectorAll(className)

      for (let i = 0; i < items.length; i++) {
        // if any hidden child elements are detected, remove their
        // hidden classes and add back to items
        if (items[i].classList.contains(extraClass)) {
          items[i].classList.remove(extraClass)
        }

        currItem = items[i].getBoundingClientRect()
        if (prevItem && prevItem.top < currItem.top) {
          wrappedItems.push(items[i])
        }
        prevItem = currItem
      }

      return wrappedItems
    },
    addClassToItem (items, classToBeAdded) {
      for (let k = 0; k < items.length; k++) {
        items[k].classList.add(classToBeAdded)
      }
    }
  }
}
