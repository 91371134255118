<script>
export default {
  name: 'TableFakeRow',
  render: function (createElement) {
    return createElement(
      'tr', { class: 'table--filterable__row' }, [
        createElement('div', { class: 'table--filterable__result' })
      ]
    )
  }
}
</script>
