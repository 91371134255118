<template>
  <div class="page--search-results">
    <search-bar
      :existing-search-term="searchTerm"
      @update:search-term="updateSearchTerm"
    />

    <button
      class="button--underlined"
      @click="clearAll"
    >
      Clear All
    </button>

    <div class="page--search-results__pane container--basic">
      <div class="page--search-results__pane-left">
        <p
          v-if="!noResults"
          class="page--search-results__text"
        >
          Results: {{ totalItems }}
        </p>

        <filters-search :filters="filters" />
      </div>

      <div class="page--search-results__pane-right">
        <filters-sort @update:sort="updateSelectedSort" />

        <search-results :results="currentItems" />
      </div>
    </div>

    <div class="page--search-results__bottom">
      <template v-if="!noResults">
        <div class="page--search-results__bottom--lhs">
          <pagination
            v-if="totalItems"
            :pages="totalPages"
            :items-number="currentItems.length"
            :total-items="totalItems"
            @update:page="updateSelectedPage"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Pagination from '../pagination/ComponentPagination'
import FiltersSearch from '../filters/FiltersSearch'
import FiltersSort from '../filters/FiltersSort'
import SearchResults from './SearchResults'
import SearchBar from '../search/SearchBar'

import mixinScrollTo from '../../mixins/mixin-scroll-to'

import { setAxiosHeaders } from '../../helpers/axios-helpers'

setAxiosHeaders(axios)

export default {
  name: 'SearchResultsPage',

  components: {
    FiltersSearch,
    FiltersSort,
    Pagination,
    SearchBar,
    SearchResults
  },

  mixins: [mixinScrollTo],

  props: {
    filters: {
      type: Array,
      default: () => []
    },

    endpointInitialResults: {
      type: String,
      required: true
    },

    endpointMoreResults: {
      type: String,
      required: true
    },

    theme: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      config: {
        acceptedQueryStringParams: ['search-term', 'theme']
      },
      noResults: false,
      searchTerm: '',
      totalItems: 0,
      totalPages: 0,
      currentItems: []
    }
  },

  created () {
    this.handleQueryString()
  },

  mounted () {
    this.fetchResults()
    this.$root.$on('update:filters', this.updateSelectedFilters)
  },

  methods: {
    clearAll () {
      this.resetUrl()
      this.resetFilters()
      this.resetPagination()
      this.resetSearch()
      this.resetSort()
      this.fetchResults()
    },

    fetchResults () {
      this.showFetchingResults()

      const data = {
        params: {
          filters: this.$store.state.search.appliedFilters,
          page: this.$store.state.search.requestedPage,
          query: this.$store.state.search.searchTerm,
          sort: this.$store.state.search.selectedSort,
          theme: this.theme
        }
      }

      axios.get(this.endpointInitialResults, data)
        .then(response => {
          this.populateValues(response)
          this.$root.$emit('results:loaded')
        })
        .catch(function (error) {
          console.log(error)
        })
    },

    getQueryStringParams (paramsFromUrl) {
      const params = []

      this.config.acceptedQueryStringParams.forEach(param => {
        if (paramsFromUrl.has(param)) { params.push(param) }
      })

      return params
    },

    /**
     * If a query string is present in the URL,
     * Initialise the state of the component based on its parameters
     * @see this.config.acceptedQueryStringParameters
     * @see created()
     */
    handleQueryString () {
      const paramsFromUrl = new URLSearchParams(window.location.search)
      const params = this.getQueryStringParams(paramsFromUrl)

      if (params.includes('search-term')) {
        this.searchTerm = paramsFromUrl.get('search-term')
      }

      if (params.includes('theme')) {
        this.filters.map(filter => {
          // TODO !!
          // filter json needs to have ids, for both filters and filter options
          // work around for now until that is changed
          // remove horrible hardcoding!
          // has been put in to avoid having to change the back-end, no time
          if (Object.keys(filter) == 'Impact Area') {
            let param = paramsFromUrl.get('theme')

            // sorry - i know :(
            if (param == 'nature-based-solutions') {
              param = 'nature-based solutions'
            } else {
              param = param.replace('-', ' ')
            }

            filter.preSelected = param
          }
        })
      }
    },

    populateValues (response) {
      if (!response.data.items) {
        this.noResults = true
      }

      this.currentItems = response.data.items
      this.totalItems = response.data.metadata.count
      this.totalPages = response.data.metadata.pages
    },

    resetFilters () {
      this.$root.$emit('reset:filter-options')
      this.$store.dispatch('search/resetFilters')
    },

    resetPagination () {
      this.$store.dispatch('search/changePage', 1)
    },

    resetSearch () {
      this.$root.$emit('reset:search')
      this.$store.dispatch('search/search', '')
    },

    resetSort () {
      this.$root.$emit('reset:sort')
      this.$store.dispatch('search/resetSort')
    },

    // Resets the URL _without_ reloading the page.
    // Otherwise, returning to the search page from a resource populates the search, filters, etc., based on the URL
    resetUrl () {
      window.history.pushState({}, '', '/search')
    },

    showFetchingResults () {
      this.$root.$emit('results:update')
      this.currentItems = []
    },

    updateSearchTerm () {
      this.resetPagination()
      this.fetchResults()
    },

    updateSelectedFilters () {
      this.resetPagination()
      this.fetchResults()
    },

    updateSelectedPage () {
      this.fetchResults()
      this.scrollToElem('.page--search-results', 500)
    },

    updateSelectedSort () {
      this.resetPagination()
      this.fetchResults()
    }
  }
}
</script>
