<template>
  <div class="navbar-search">
    <div class="navbar-search__inner container--center-vh">
      <form
        id="site-search-form"
        action="/search"
        method="get"
      >
        <input
          autocomplete="off"
          name="query"
          value=""
          placeholder="Search"
          type="text"
          class="navbar-search__input"
        >
        <div class="icon--search">
          <icon-search />
        </div>
      </form>
      <button
        type="button"
        class="icon--cross"
        @click="toggleOffSearch"
      >
        <icon-cross />
        <span class="visually-hidden">Return to normal navbar</span>
      </button>
    </div>
  </div>
</template>

<script>
import IconCross from '../icons/IconCross'
import IconSearch from '../icons/IconSearch'

export default {
  name: 'NavbarSearch',

  components: {
    IconCross,
    IconSearch
  },

  emits: [
    'search:inactive'
  ],

  methods: {
    toggleOffSearch () {
      this.$emit('search:inactive')
    }
  }
}
</script>
