<template>
  <td>
    <small
      v-if="isMobileOrTablet"
      class="table--filterable__cell-header"
    >
      {{ header }}
    </small>
    <slot name="content" />
  </td>
</template>

<script>
export default {
  name: 'TableFilterableCell',
  props: {
    header: {
      type: String,
      default: ''
    },
    isMobile: Boolean,
    isTablet: Boolean
  },
  computed: {
    isMobileOrTablet () {
      return this.isMobile || this.isTablet
    }
  }
}
</script>
