<template>
  <div class="carousel__navigation">
    <button
      class="button--pagination"
      @click="previous"
    >
      <div class="icon--left-chevron">
        <icon-left-chevron />
      </div>
    </button>
    <button
      class="button--pagination"
      @click="next"
    >
      <div class="icon--right-chevron">
        <icon-right-chevron />
      </div>
    </button>
  </div>
</template>

<script>
import IconLeftChevron from '../icons/IconLeftChevron'
import IconRightChevron from '../icons/IconRightChevron'

export default {
  name: 'CarouselNavigation',

  components: {
    IconLeftChevron,
    IconRightChevron
  },

  emits: [
    'next',
    'previous'
  ],

  methods: {
    next () {
      this.$emit('next')
    },
    previous () {
      this.$emit('previous')
    }
  }
}
</script>
